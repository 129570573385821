import React from "react"
import { Link } from "gatsby"
import Bio from "./bio"
import { useStaticQuery, graphql } from "gatsby"

const Header = () => (
  <div className="font-sans md:font-serif">
    <div className="flex flex-row justify-center">
      <Bio />
    </div>
  </div>
)

export default Header
