/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Image from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
          }
          social {
            email
            github
            linkedin
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  const avatar = data?.avatar?.childImageSharp?.fixed

  return (
    <div className="flex flex-row items-center">
      {avatar && (
        <Link to="/">
          <Image
            fixed={avatar}
            alt={author?.name || ``}
            className=""
            imgStyle={{
              borderRadius: `50%`,
            }}
          />
        </Link>
      )}
      {author?.name && (
        <p className="ml-4 text-orange-600">
          <strong>{author.name}</strong>
          {` `}
          <br />
          <a
            className="underline text-blue-400"
            href={`${social?.linkedin || ``}`}
          >
            Linkedin
          </a>
          {` | `}
          <a
            className="underline text-blue-400"
            href={`${social?.github || ``}`}
          >
            Github
          </a>
          {` | `}
          <a
            className="underline text-blue-400"
            href={`mailto:${social?.email || ``}`}
          >
            Email
          </a>
        </p>
      )}
    </div>
  )
}

export default Bio
